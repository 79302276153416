<template>
  <div>
    <v-card>
      <v-card-title class="mt-4 mb-2">
        Alarm Kuralları
      </v-card-title>
      <v-card-text>
        <table dense>
          <thead>
            <tr>
              <th
                v-for="item in headers"
                :key="item.value"
                :width="item.width"
              >
                {{ item.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in rules"
              :key="i"
            >
              <td>
                <div class="mt-0 mb-0 pt-0 pb-0 ml-4 mr-4">
                  {{ item["alarmName"] }}
                </div>
              </td>
              <td>
                <v-switch
                  v-model="item.status"
                  class="mt-2 mb-0 pt-0 pb-0 ml-4 mr-4"
                  inset
                />
              </td>
              <td>
                <div class="text-center">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, i)"
                  >
                    mdi-pencil
                  </v-icon>

                  <v-icon
                    small
                    @click="deleteItem(item, i)"
                  >
                    mdi-delete
                  </v-icon>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary darken-1"
          text
          @click="newRule"
        >
          Kuralı Ekle
        </v-btn>
      </v-card-actions>
    </v-card>

    <Dialog
      v-model="clientAddAlarmModel"
      width="650px"
    >
      <Card>
        <v-card-title>
          Kural Ekle
          <v-spacer />

          <Button
            class="mr-2"
            color="indigo"
            :min-width="'30px'"
            :small="true"
            :elevation="0"
            :outlined="true"
            :button-style="{padding: '3px 3px 3px 3px'}"
            @click="clientAddAlarmModel = false"
          >
            <v-icon>mdi-close</v-icon>
          </Button>
        </v-card-title>
        <v-card-text>
          <v-col
            cols="12"
            class=""
          >
            <v-text-field
              v-model="ruledata.alarmName"
              label="İsim*"
              single-line
              outlined
              dense
              hide-details
            />

            <!--  <v-select
              v-model="alarmTypeModel"
              :items="alarmType"
              label="Kural Tipi"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              dense
              required
              hide-details
            >
            </v-select> -->

            <v-select
              ref="ruledata.santral"
              v-model="ruledata.santral"
              :items="santralList"
              label="santral"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              dense
              required
              hide-details
            />
            <v-select
              v-model="ruledata.channel"
              :items="alarmDeviceTypeList"
              label="Cihaz Tipi"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              dense
              required
              hide-details
            />

            <v-select
              v-if="ruledata.channel == 'INVERTER'"
              v-model="ruledata.device"
              :items="inverterList[ruledata.santral]"
              label="Inverter"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              multiple
              dense
              required
              hide-details
            >
              <template v-slot:selection="{item, index}">
                <span
                  v-if="index === 0"
                  style="max-width: 120px"
                  class="d-inline-block text-truncate"
                >{{ item.text
                }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >(+{{ ruledata.device.length - 1 }})</span>
              </template>
            </v-select>

            <v-select
              v-if="ruledata.channel == 'INVERTER'"
              v-model="ruledata.measure"
              :items="inverterMeasureList"
              label="Inverter"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              dense
              multiple
              required
              hide-details
            >
              <template v-slot:selection="{item, index}">
                <span
                  v-if="index === 0"
                  style="max-width: 120px"
                  class="d-inline-block text-truncate"
                >{{ item.text
                }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >(+{{ ruledata.device.length - 1 }})</span>
              </template>
            </v-select>
            <v-select
              v-if="ruledata.channel == 'STRINGBOX'"
              v-model="ruledata.device"
              :items="stringboxList[ruledata.santral]"
              label="Stringbox"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              multiple
              dense
              required
              hide-details
            >
              <template v-slot:selection="{item, index}">
                <span
                  v-if="index === 0"
                  style="max-width: 120px"
                  class="d-inline-block text-truncate"
                >{{ item.text
                }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >(+{{ ruledata.device.length - 1 }})</span>
              </template>
            </v-select>

            <v-select
              v-if="ruledata.channel == 'STRINGBOX'"
              v-model="ruledata.measure"
              :items="stringboxMeasureList"
              label="Stringbox"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              dense
              multiple
              required
              hide-details
            >
              <template v-slot:selection="{item, index}">
                <span
                  v-if="index === 0"
                  style="max-width: 120px"
                  class="d-inline-block text-truncate"
                >{{ item.text
                }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >(+{{ ruledata.measure.length - 1 }})</span>
              </template>
            </v-select>

            <v-select
              v-if="ruledata.channel == 'SENSOR'"
              v-model="ruledata.device"
              :items="sensorList[ruledata.santral]"
              label="Sensor"
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              multiple
              dense
              required
              hide-details
            >
              <template v-slot:selection="{item, index}">
                <span
                  v-if="index === 0"
                  style="max-width: 120px"
                  class="d-inline-block text-truncate"
                >{{ item.text
                }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >(+{{ ruledata.device.length - 1 }})</span>
              </template>
            </v-select>

            <v-select
              v-if="ruledata.channel == 'SENSOR'"
              v-model="ruledata.measure"
              :items="sensorMeasureList"
              label="Sensor"
              class="mb-0 mt-3 pt-1 pb-0"
              multiple
              outlined
              dense
              required
              hide-details
            >
              <template v-slot:selection="{item, index}">
                <span
                  v-if="index === 0"
                  style="max-width: 120px"
                  class="d-inline-block text-truncate"
                >{{ item.text
                }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >(+{{ ruledata.measure.length - 1 }})</span>
              </template>
            </v-select>

            <v-select
              v-model="ruledata.severity_id"
              :items="severity"
              label="Seviye* "
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              dense
              required
              hide-details
            />

            <v-select
              v-model="ruledata.condition"
              :items="condition"
              label="Koşul* "
              class="mb-0 mt-3 pt-1 pb-0"
              outlined
              dense
              required
              hide-details
            />

            <v-col class="d-flex mb-0 mt-1 pt-1 pb-0 ml-0 pl-0 mr-0 pr-0">
              <v-text-field
                v-if="['inrange', 'notrange'].indexOf(ruledata.condition) == -1"
                v-model="ruledata.value"
                class="mb-0 mt-0 pt-1 pb-0 ml-1"
                label="Deger*"
                number
                single-line
                outlined
                dense
                hide-details
              />
              <v-text-field
                v-if="['inrange', 'notrange'].indexOf(ruledata.condition) > -1"
                v-model="ruledata.min"
                label="min*"
                class="mb-0 mt-0 pt-1 pb-0 mr-1"
                number
                single-line
                outlined
                dense
                hide-details
              />

              <v-text-field
                v-if="['inrange', 'notrange'].indexOf(ruledata.condition) > -1"
                v-model="ruledata.max"
                class="mb-0 mt-0 pt-1 pb-0 ml-1"
                label="maks*"
                number
                single-line
                outlined
                dense
                hide-details
              />
            </v-col>

            <v-col class="d-flex mb-0 mt-1 pt-1 pb-0 ml-0 pl-0 mr-0 pr-0">
              <!-- inverter seçili ise-->

              <!-- inverter  measure select-->
              <v-select
                v-if="ruledata.channel == 'STRINGBOX'"
                v-model="ruledata.extraRule.measure"
                :items="inverterMeasureList"
                label="Ölçüm* "
                class="mb-0 mt-3 pt-1 pb-0"
                outlined
                dense
                required
                hide-details
              />
              <!-- koşul gir-->
              <v-select
                v-if="ruledata.channel == 'STRINGBOX'"
                v-model="ruledata.extraRule.condition"
                :items="condition"
                label="Koşul* "
                class="mb-0 mt-3 pt-1 pb-0 ml-1"
                outlined
                dense
                required
                hide-details
              />

              <v-text-field
                v-if="ruledata.channel == 'STRINGBOX'"
                v-model="ruledata.extraRule.value"
                class="mb-0 mt-3 pt-1 pb-0 ml-1"
                label="Deger*"
                number
                single-line
                outlined
                dense
                hide-details
              />
            </v-col>

            <!--   <v-col class="d-flex mb-0 mt-1 pt-1 pb-0 ml-0 pl-0 mr-0 pr-0">

              <v-select
                v-if="ruledata.channel == 'INVERTER'"
                v-model="ruledata.extraRule.measure"
                :items="inverterMeasureList"
                label="Ortalama Ölçüm* "
                class="mb-0 mt-3 pt-1 pb-0"
                outlined
                dense
                required
                hide-details
              />

              <v-select
                v-if="ruledata.channel == 'INVERTER'"
                v-model="ruledata.extraRule.condition"
                :items="condition1"
                label="Koşul* "
                class="mb-0 mt-3 pt-1 pb-0 ml-1"
                outlined
                dense
                required
                hide-details
              />

              <v-text-field
                v-if="ruledata.channel == 'INVERTER'"
                v-model="ruledata.extraRule.value"
                class="mb-0 mt-3 pt-1 pb-0 ml-1"
                label="Deger %"
                number

                outlined
                dense
                hide-details
              />
            </v-col> -->
            <v-col class="d-flex mb-0 mt-0 pt-1 pb-0 ml-0 pl-0 mr-0 pr-0">
              <v-card class="pl-2 pr-2 col-12">
                <span class="text-h7 mb-0 pb-0">
                  <b>
                    <v-switch
                      v-model="ruledata.delaystatus"
                      label="Geçikme"
                      inset
                    />
                    ({{ ruledata.delay }} dakika)
                  </b>
                  Sistem, alarmı açmadan önce gecikmeyi bekler.
                </span>

                <v-slider
                  v-model="ruledata.delay"
                  class="align-center"
                  max="200"
                  min="10"
                  hide-details
                />
              </v-card>
            </v-col>

            <v-col class="d-flex mb-0 mt-0 pt-1 pb-0 ml-0 pl-0 mr-0 pr-0">
              <v-card class="pl-2 pr-2 col-12">
                <span class="text-h7">
                  <b>
                    <v-switch
                      v-model="ruledata.ignore_night_status"
                      label="Karanlığı Yoksay"
                      inset
                    />
                    Karanlığı Yoksay <br>({{ ruledata.ignore_night }}
                    dakika)
                  </b>
                </span>
                Gün doğumundan sonra ve gün batımından önce seçilen dakika kadar uyarı kontrolünü
                atla
                <v-slider
                  v-model="ruledata.ignore_night"
                  class="align-center"
                  max="200"
                  min="10"
                  hide-details
                />
              </v-card>
            </v-col>

            <v-col class="d-flex mb-0 mt-0 pt-1 pb-0">
              <v-switch
                v-model="ruledata.status"
                label="Durum"
                inset
              />
            </v-col>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary darken-1"
            text
            @click="save"
          >
            Ayarı Kaydet
          </v-btn>
        </v-card-actions>
      </Card>
    </Dialog>
  </div>
</template>
<script>
import { setAlarm } from '@/api/Request/alarm';
import i18n from '@/locale';
import helper from '@/api/helper';

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['item'],
  data: () => ({
    headers: [],
    dessert: [],
    prefix: '',
    companyCode: '',
    timeperiod: [],
    rules: [],
    clientAddAlarmModel: false,
    alarmType: [],
    alarmTypeModel: '',

    seletedSantral: '',

    alarmDeviceTypeModel: '',
    alarmDeviceTypeList: [],
    alarmName: '',
    conditionModel: '',

    // inverterList: [],
    inverterModel: [],
    // stringboxList: [],
    stringboxModel: [],
    // sensorList: [],
    sensorModel: [],
    // inverterList: [],

    stringboxMeasureList: [],
    sensorMeasureList: [],

    inverterMeasureModel: [],
    stringboxMeasureModel: [],
    sensorMeasureModel: [],
    status: false,
    alarmValue: null,
    maksValue: null,
    minValue: null,
    selectedRule: {},
    selectedIndex: null,

    ruledata: {},
  }),
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    inverterList() {
      const { santralInverterData } = this.$store.getters;
      if (Object.keys(santralInverterData).length > 0) {
        const inverterList = {};
        Object.keys(santralInverterData).forEach((i) => {
          const inverter = santralInverterData[i];
          const temp = [];
          Object.keys(inverter).forEach((k) => {
            temp.push({
              text: inverter[k].INVERTERNAME,
              value: inverter[k].INVERTER,
            });
          });
          inverterList[i] = temp;
        });
        return inverterList;
      }
      return [];
    },
    // eslint-disable-next-line vue/return-in-computed-property
    inverterMeasureList() {
      const { inverterMeasureList } = this.$store.getters;

      if (Object.keys(inverterMeasureList).length > 0) {
        return inverterMeasureList;
      }
      return [];
    },
    // eslint-disable-next-line vue/return-in-computed-property
    santralList() {
      const { santralRawData } = this.$store.getters;
      const santral = [];
      if (Object.keys(santralRawData).length > 0) {
        Object.keys(santralRawData).forEach((i) => {
          santral.push({
            text: santralRawData[i].santral,
            value: santralRawData[i].santralCode,
          });
        });

        return santral;
      }
      return [];
    },

    stringboxList() {
      const { santralStringboxData, stringboxMeasureList } = this.$store.getters;
      const stringList = {};
      Object.keys(santralStringboxData).forEach((i) => {
        const strinbox = santralStringboxData[i];
        const temp = [];
        Object.keys(strinbox).forEach((k) => {
          temp.push({
            text: strinbox[k].STRINGBOXNAME,
            value: strinbox[k].STRINGBOX,
          });
        });
        stringList[i] = temp;
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.stringboxMeasureList = stringboxMeasureList;
      return stringList;
    },
    sensorList() {
      const { santralSensorData, sensorMeasures } = this.$store.getters;
      const sensorList = {};

      Object.keys(santralSensorData).forEach((i) => {
        const sensor = santralSensorData[i];
        const temp = [];
        Object.keys(sensor).forEach((k) => {
          temp.push({
            text: sensor[k].SENSORNAME,
            value: sensor[k].SENSOR,
          });
        });
        sensorList[i] = temp;
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.sensorMeasureList = sensorMeasures;
      return sensorList;
    },
    severity() {
      const { severity } = this.$store.getters;
      const val = [];
      if (!helper.isEmpty(severity)) {
        severity.forEach((item) => {
          val.push(
            { text: i18n.t(item.text), value: item.value },
          );
        });
      }
      return val;
    },
  },
  created() {
    this.newRuleData();
    this.alarmType = [
      { text: 'Cihaz', value: 'device' },
      { text: 'Terim', value: 'term' },
    ];

    this.alarmDeviceTypeList = [
      { text: 'İnverter', value: 'INVERTER' },
      { text: 'String', value: 'STRINGBOX' },
      { text: 'Sensör', value: 'SENSOR' },
    ];

    this.condition = [
      { text: 'Eşit', value: 'eq' },
      { text: 'Küçüktür ', value: 'max' },
      { text: 'Büyüktür', value: 'min' },
      { text: 'Aralık içinde', value: 'inrange' },
      { text: 'Aralık dışında', value: 'notrange' },
    ];

    this.condition1 = [

      { text: 'Küçüktür ', value: 'max' },
      { text: 'Büyüktür', value: 'min' },

    ];

    this.headers = [
      { text: 'İsim', value: 'alarmName', width: '200px' },
      { text: 'Durum', value: 'status', width: '300px' },
      { text: 'Seçenekler', value: 'options', width: '200px' },
    ];
    this.selectedFarmFunc();
  },
  mounted() {
    this.dessert = this.item;

    if ('rules' in this.item) {
      this.rules = this.item.rules;
    }
  },

  methods: {
    newRuleData() {
      this.ruledata = {
        alarmName: '',
        santral: '',
        channel: '',
        severity_id: '',
        condition: '',
        status: false,
        ignore_night: '',
        delay: '',
        device: [],
        measure: '',
        max: '',
        min: '',
        value: '',
        ignore_night_status: false,
        delaystatus: false,
        extraRule: {
          measure: '',
          condition: '',
          value: '',
          channel: 'INVERTER',
        },
      };
    },
    async selectedFarmFunc() {
      const { selectedFarm } = await this.$store.getters;
      this.companyCode = selectedFarm.companyCode;
      this.prefix = selectedFarm.prefix;
    },

    newRule() {
      this.clientAddAlarmModel = true;
      this.selectedIndex = null;
      this.newRuleData();
    },

    save() {
      if (!Object.hasOwn(this.item, 'rules')) {
        this.item.rules = [];
      }

      if (!Array.isArray(this.item.rules)) {
        this.item.rules = [];
      }

      if (this.selectedIndex == null) {
        this.item.rules.push(this.ruledata);
      } else {
        this.item.rules[this.selectedIndex] = this.ruledata;
      }
      this.clientAddAlarmModel = false;
      const params = {
        station: {
          prefix: this.prefix,
          companyCode: this.companyCode,
        },
        condiniton: {
          alarmCode: 'UserDefinedAlarms',
        },
        data: this.item,
      };

      setAlarm(params).then((r) => {
        console.log(r);
      });
    },
    deleteItem(item, i) {
      const rules = this.item.rules.splice(i, 1);
      this.item.rules = rules;

      const params = {
        station: {
          prefix: this.prefix,
          companyCode: this.companyCode,
        },
        condiniton: {
          alarmCode: 'UserDefinedAlarms',
        },
        data: this.item,
      };

      setAlarm(params).then((r) => {
        console.log(r);
      });
    },
    editItem(rule, index) {
      this.newRuleData();
      this.ruledata = { ...this.ruledata, ...rule };
      this.ruledata.extraRule.channel = 'INVERTER';
      this.clientAddAlarmModel = true;
      this.selectedIndex = index;
    },
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

td {
  border: 1px solid #dddddd;
  text-align: left;
}
</style>
