<template>
  <div>
    <v-card>
      <v-card-title class="mt-4 mb-2">
        Alarm Kuralları
      </v-card-title>
      <v-card-text>
        <table dense>
          <thead>
            <tr>
              <th
                v-for="item in headers"
                :key="item.value"
                :width="item.width"
              >
                {{ item.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in dessert"
              :key="i"
            >
              <td>
                <div class="mt-0 mb-0 pt-0 pb-0 ml-4 mr-4">
                  {{ item.inverterManufacture }}
                </div>
              </td>
              <td>
                <div class="mt-2 mb-0 pt-0 pb-0 ml-4 mr-4">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, i)"
                  >
                    mdi-open-in-new
                  </v-icon>
                </div>
              </td>

              <td>
                <v-text-field
                  v-model="item.ignore_night"
                  class="mb-0 mt-1 pt-1 pb-0 ml-2 mr-2"
                  number
                  x-small
                  outlined
                  dense
                  hide-details
                />
              </td>
              <td>
                <v-text-field
                  v-model="item.timeperiod"
                  class="mb-0 mt-1 pt-1 pb-0 ml-2 mr-2"
                  number
                  x-small
                  outlined
                  dense
                  hide-details
                />
              </td>
              <td>
                <v-switch
                  v-model="item.status"
                  class="mt-2 mb-0 pt-3 pb-0 ml-4 mr-4"
                  inset
                />
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary darken-1"
          text
          @click="save"
        >
          Kuralı Kaydet
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="errorListModel"
      width="800px"
    >
      <v-card>
        <v-card-title>{{ inverterModel }} Hata ve Uyarı listesi</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="error_headers"
            :items="error_dessert"
            :items-per-page="5"
            class="elevation-1"
            dense
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary darken-1"
            text
            @click="errorListModel = false"
          >
            Kapat
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { getInverterErrorList, getInverterModel } from '@/api/Request/inverter';
import { setAlarm } from '@/api/Request/alarm';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['item'],
  data: () => ({
    headers: [],
    dessert: [],
    prefix: '',
    companyCode: '',
    timeperiod: '',
    timeperiodList: [],
    rules: [],
    errorListModel: false,
    error_dessert: [],
    error_headers: [],
    inverterModel: null,
  }),
  created() {
    this.headers = [
      { text: 'Inverter Marka', value: 'inverterManufacture', width: '150px' },
      { text: 'Hata ve uyarı', value: 'errors', width: '100px' },
      { text: 'Karanlığı Yoksay', value: 'ignore_night', width: '100px' },
      { text: 'Geçikme', value: 'timeperiod', width: '100px' },
      { text: 'Durum', value: 'status', width: '100px' },
    ];

    this.error_headers = [
      { text: 'Hata kodu', value: 'code', width: '200px' },
      { text: 'Hata açıklaması', value: 'message', width: '300px' },
      { text: 'Tip', value: 'type', width: '200px' },
    ];

    for (let m = 10; m < 60; m += 10) {
      this.timeperiodList.push({
        text: `${m} dakika`,
        value: `${m}minute`,
      });
    }

    for (let h = 1; h < 25; h += 1) {
      this.timeperiodList.push({
        text: `${h} saat`,
        value: `${h}hour`,
      });
    }

    this.selectedFarmFunc();
  },
  mounted() {
    if (!('rules' in this.item)) {
      this.item.rules = [];
    }

    getInverterModel().then((res) => {
      this.dessert = res.data.data;
      const dessert = [];
      Object.keys(res.data.data).forEach((i) => {
        const rules = this.item.rules.filter(
          (rule) => rule.inverterManufacture === res.data.data[i].inverterManufacture,
        )[0];
        console.log(rules);
        let obj = {};
        if (rules !== undefined) {
          obj = {
            inverterManufacture: rules.inverterManufacture || res.data.data[i].inverterManufacture,
            INVERTERMODEL: rules.INVERTERMODEL || res.data.data[i].INVERTERMODEL,
            timeperiod: rules.timeperiod || 10,
            ignore_night: rules.ignore_night || 50,
            status: rules.status || false,
            errors: rules.errors || [],
          };
        } else {
          obj = {
            inverterManufacture: res.data.data[i].inverterManufacture,
            INVERTERMODEL: res.data.data[i].INVERTERMODEL,
            timeperiod: 10,
            ignore_night: 50,
            status: false,
            errors: [],
          };
        }
        dessert.push(obj);
      });
      this.dessert = dessert;
      this.item.rules = dessert;
      this.getInverterError();
    });
  },

  methods: {
    async selectedFarmFunc() {
      const { selectedFarm } = await this.$store.getters;
      this.companyCode = selectedFarm.companyCode;
      this.prefix = selectedFarm.prefix;
    },

    editItem(item) {
      this.error_dessert = item.errors;
      this.inverterModel = item.inverterManufacture;
      this.errorListModel = true;
    },
    getModelList() {
      const listed = [];
      this.dessert.forEach((item) => {
        listed.push(item.INVERTERMODEL);
      });
      return listed;
    },
    getInverterError() {
      const models = this.getModelList();

      const payload = {
        station: {
          companyCode: this.companyCode,
          prefix: this.prefix,
        },
        condiniton: {
          models,
        },
      };

      getInverterErrorList(payload).then((res) => {
        Object.keys(res.data.data).forEach((i) => {
          this.dessert.forEach((item) => {
            if (item.INVERTERMODEL === i) {
              // eslint-disable-next-line no-param-reassign
              item.errors = res.data.data[item.INVERTERMODEL];
            }
          });
        });
      });
    },

    save() {
      this.item.rules = this.dessert;
      const params = {
        station: {
          prefix: this.prefix,
          companyCode: this.companyCode,
        },
        condiniton: {
          alarmCode: this.item.alarmCode,
        },
        data: this.item,
      };

      setAlarm(params).then((r) => {
        console.log(r);
      });
    },
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

td {
  border: 1px solid #dddddd;
  text-align: left;
}
</style>
