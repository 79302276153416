<template>
  <div>
    <Dialog
      v-model="dialog"
      :persistent="true"
      max_width="500px"
      height="500px"
    >
      <Card>
        <v-card-title>
          <span class="blue--text text-h6"> Alarm Ekle</span>
          <v-spacer />
          <Button
            color="indigo"
            :min-width="'30px'"
            :small="true"
            :elevation="0"
            :outlined="true"
            :button-style="{padding: '3px 3px 3px 3px'}"
            @click="$emit('newalarm')"
          >
            <v-icon>mdi-close</v-icon>
          </Button>
        </v-card-title>

        <v-divider />
        <v-card-text>
          <div
            v-for="(item, i) in alarmList"
            :key="i"
            class="d-flex mt-1 mb-1"
          >
            <v-checkbox
              dense
              hide-details
              class="mt-0 mb-0 pt-0 pb-0"
              :input-value="alarmModel[i]"
              color="primary"
              @change="setAlarmUpdate(item, i)"
            />

            <span class="mt-0 mb-0 pt-0 pb-0">
              {{ item.text }}
            </span>
          </div>
        </v-card-text>
      </Card>
    </Dialog>
  </div>
</template>

<script>

import { setAlarm } from '@/api/Request/alarm';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['dialog'],
  data: () => ({
    // alarmList: [],
    alarmModel: {},
    selectIndex: null,
  }),
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    alarmList() {
      const { alarmlist } = this.$store.getters;

      if (Object.keys(alarmlist).length > 0) {
        Object.keys(alarmlist).forEach((r) => {
          this.alarmModel[r] = alarmlist[r].status === 'Active';
        });

        return alarmlist;
      }
      return [];
    },
  },
  watch: {
    dialog() {
      const params = {
        station: {
          companyCode: this.companyCode,
          prefix: this.prefix,
        },
      };
      this.$store.dispatch('getAlarmList', params);
    },
  },
  created() {
    this.selectedFarmFunc();
  },
  methods: {
    async selectedFarmFunc() {
      const { selectedFarm } = await this.$store.getters;
      this.companyCode = selectedFarm.companyCode;
      this.prefix = selectedFarm.prefix;
    },
    setAlarmUpdate(item, i) {
      this.selectIndex = i;
      const alarmdata = { ...item };

      if (item.status === 'Active') {
        this.alarmModel[i] = 'Passive';
        alarmdata.status = 'Passive';
      } else if (item.status === 'Passive') {
        this.alarmModel[i] = 'Active';
        alarmdata.status = 'Active';
      }

      const params = {
        station: {
          companyCode: this.companyCode,
          prefix: this.prefix,
        },
        condiniton: {
          alarmCode: alarmdata.alarmCode,
        },
        data: alarmdata,
      };

      setAlarm(params).then((r) => {
        console.log(r);
      });
    },
  },
};
</script>
