<template>
  <div>
    <v-card>
      <v-card-title class="mt-4 mb-2">
        Alarm Kuralları
      </v-card-title>
      <v-card-text>
        <table dense>
          <thead>
            <tr>
              <th
                v-for="item in headers"
                :key="item.value"
                :width="item.width"
              >
                {{ item.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in rules"
              :key="i"
            >
              <td>
                <div class="mt-0 mb-0 pt-0 pb-0 ml-4 mr-4">
                  {{ item["device"] }}
                </div>
              </td>
              <td>
                <v-select
                  v-model="item['timeperiod']"
                  class="mt-1 mb-1 ml-4 mr-4 pt-1 pb-1"
                  label=" "
                  dense
                  attach=""
                  outlined
                  hide-details
                  :items="timeperiodList"
                />
              </td>

              <td>
                <v-switch
                  v-model="item.status"
                  class="mt-2 mb-0 pt-0 pb-0 ml-4 mr-4"
                  inset
                >
                  <span
                    v-if="item.status == true"
                    class="mt-5"
                  >Aktif</span>
                  <span
                    v-else
                    class="mt-5"
                  >Pasif</span>
                </v-switch>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary darken-1"
          text
          @click="save(item)"
        >
          Kuralı Kaydet
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { getDataloggerList } from '@/api/Request/datalogger';
import helper from '@/api/helper';
import { setAlarm } from '@/api/Request/alarm';

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['item'],
  data: () => ({
    headers: [],
    dessert: [],
    prefix: '',
    companyCode: '',
    timeperiod: '',
    timeperiodList: [],
    rules: [],
  }),
  created() {
    this.headers = [
      { text: 'Veri kaydedici', value: 'deviceName', width: '200px' },
      { text: 'Geçikme Periyodu', value: 'timeperiod', width: '300px' },
      { text: 'Durum', value: 'status', width: '200px' },
    ];
    this.timeperiodList = [];

    for (let m = 10; m < 60; m += 10) {
      this.timeperiodList.push({
        text: `${m} dakika`,
        value: `${m}minute`,
      });
    }

    for (let h = 1; h < 25; h += 1) {
      this.timeperiodList.push({
        text: `${h} saat`,
        value: `${h}hour`,
      });
    }
  },
  mounted() {
    this.dessert = this.item;
    if (!('rules' in this.item)) {
      this.item.rules = [];
    }
    this.selectedFarmFunc();
  },

  methods: {
    async selectedFarmFunc() {
      const { selectedFarm } = await this.$store.getters;
      this.companyCode = selectedFarm.companyCode;
      this.prefix = selectedFarm.prefix;
      this.getDatalogger();
    },

    getDatalogger() {
      const params = {
        condiniton: {
          prefix: this.prefix,
        },
      };

      const dessert = [];
      this.dessert = [];
      getDataloggerList(params).then((res) => {
        if (helper.resIsEmpty(res)) {
          Object.keys(res.data.data).forEach((r) => {
            const rules = this.item.rules.filter(
              (rule) => rule.device === res.data.data[r].deviceName,
            )[0];
            let obj = {};
            if (rules !== undefined) {
              obj = {
                device: res.data.data[r].deviceName,
                timeperiod: rules.timeperiod || '1hour',
                status: rules.status || false,
              };
            } else {
              obj = {
                device: res.data.data[r].deviceName,
                timeperiod: '1hour',
                status: false,
              };
            }
            dessert.push(obj);
          });
          this.rules = dessert;
        }
      });
    },

    save() {
      this.item.rules = this.rules;
      const params = {
        station: {
          prefix: this.prefix,
          companyCode: this.companyCode,
        },
        condiniton: {
          alarmCode: 'DataLoggerAccess',
        },
        data: this.item,
      };
      setAlarm(params).then((r) => {
        console.log(r);
      });
    },
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

td {
  border: 1px solid #dddddd;
  text-align: left;
}
</style>
