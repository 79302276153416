<template>
  <div class="col-12">
    <v-card>
      <v-card-title class="">
        <span class="blue--text text-h6"> Alarm Ekle </span>
        <v-spacer />

        <v-btn
          class="mt-2"
          outlined
          color="indigo"
          dense
          @click="addAlarmModel = true"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Alarm Ayarla
        </v-btn>
      </v-card-title>

      <v-col class="col-12 text-right">
        <v-data-table
          :headers="headers"
          :items="desserts"
          dense
          :items-per-page="itemPerPage"
          class="elevation-1"
          :sort-by="['text', 'description', 'status', 'action']"
        >
          <template v-slot:item.description="{item}">
            <span v-html="$t('alarm.' + item.alarmCode)" />
          </template>

          <template v-slot:item.status="{item}">
            <span
              v-if="item.status === 'Active'"
              v-html="$t('global.Active')"
            />
            <span
              v-else
              v-html="$t('global.Passive')"
            />
          </template>
          <template v-slot:item.action="{item}">
            <v-icon
              small
              class="mr-2 "
              @click="itemOpen(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-card>

    <newAlarm
      :dialog="addAlarmModel"
      @newalarm="addAlarmModel = false"
    />
    <alarmSetting
      :userlist="userList"
      :dialog="settingAlarmModel"
      :item="selectedItem"
      @setAlarmUpdate="setAlarmUpdate"
      @alarmsetting="settingAlarmModel = false"
    />
  </div>
</template>

<script>
import newAlarm from '@/views/components/Station/Alarm/newAlarm.vue';
import alarmSetting from '@/views/components/Station/Alarm/alarmSetting.vue';
import { getUserList } from '@/api/Request/user';
import { setAlarm } from '@/api/Request/alarm';
import helper from '@/api/helper';

export default {
  components: { newAlarm, alarmSetting },
  data: () => ({
    addAlarmModel: false,
    selectedItem: {},
    settingAlarmModel: false,
    userList: [],
    itemPerPage: 10,
    formData: {},
  }),

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    desserts() {
      const { alarmlist } = this.$store.getters;
      if (Object.keys(alarmlist).length > 0) {
        return alarmlist.filter((r) => r.status === 'Active');
      }
      return [];
    },
    headers() {
      return [
        { text: 'Adı', value: 'text', width: '300px' },
        { text: 'Açıklama', value: 'description' },
        { text: 'Durum', value: 'status', width: '200px' },
        { text: 'Seçenek', value: 'action', width: '200px' },
      ];
    },
  },
  created() {
    const storage = window.localStorage;
    // eslint-disable-next-line no-underscore-dangle
    const _token = helper.parseJwt(storage.getItem('vuex_user'));
    this.formData.adminCompanyCode = _token.data.adminCompanyCode;
    this.selectedFarmFunc();
  },
  mounted() {
  },
  methods: {
    async selectedFarmFunc() {
      const { selectedFarm } = await this.$store.getters;
      this.companyCode = selectedFarm.companyCode;
      this.prefix = selectedFarm.prefix;

      this.getList();
      this.getUser();
    },

    itemOpen(item) {
      if (!('userlist' in item)) {
        // eslint-disable-next-line no-param-reassign
        item.userlist = [];
      }

      if (!helper.isEmpty(this.userList)) {
        if (!helper.isEmpty(this.userList.mail)) {
          // eslint-disable-next-line no-param-reassign
          const userlist = [];

          this.userList.mail.forEach((i) => {
            if (item.userlist.indexOf(i.value) > -1) {
              userlist.push({ text: i.text, value: i.value });
            }
          });

          if (userlist.length > 0) {
            // eslint-disable-next-line no-param-reassign
            item.userlist = userlist;
          }
        }
      }

      this.selectedItem = item;
      this.settingAlarmModel = true;
    },
    getList() {
      const params = {
        station: {
          companyCode: this.companyCode,
          prefix: this.prefix,
        },
        condiniton: {},
      };
      this.$store.dispatch('getAlarmList', params);
    },
    getUser() {
      const params = {
        condiniton: {
          userRole: this.userRoleFilter,
          adminCompanyCode: this.formData.adminCompanyCode,
        },
      };

      const users = [];
      const telegram = [];

      // eslint-disable-next-line vue/no-async-in-computed-properties
      getUserList(params).then((res) => {
        if (helper.resIsEmpty(res)) {
          const rest = res.data.data;

          if (rest.length > 0) {
            Object.keys(rest).forEach((i) => {
              let f = '';
              let l = '';
              if ('firstname' in rest[i]) {
                f = rest[i].firstname;
              }

              if ('lastname' in rest[i]) {
                l = rest[i].lastname;
              }

              if ('telegram' in rest[i]) {
                rest[i].telegram.forEach((element) => {
                  if (telegram.indexOf(element) === -1) {
                    telegram.push(element);
                  }
                });
              }

              users.push({
                // text: `${f} ${l} (` + rest[i].username + " )",
                text: `${f} ${l} (${rest[i].username})`,
                value: rest[i].username,
              });
            });
          }
        }

        this.userList = {
          mail: users,
          telegram,
        };
      });
    },

    setAlarmUpdate(item) {
      const params = {
        condiniton: {
          alarmCode: item.alarmCode,
        },
        station: {
          companyCode: this.companyCode,
          prefix: this.prefix,
        },
        data: item,
      };

      setAlarm(params).then(() => {
        // eslint-disable-next-line no-shadow
        const params = {
          condiniton: {
            companyCode: this.companyCode,
            prefix: this.prefix,
          },
        };
        this.$store.dispatch('getAlarmList', params);
      });
    },
  },
};
</script>

<style scoped></style>
