<template>
  <div>
    <Dialog
      v-model="dialog"
      :persistent="true"
      max_width="800px"
      width="800px"
      height="700px"
    >
      <div
        style="height: 50px; background-color: white"
        class="text-h5 pt-2 pb-1 d-flex "
      >
        <span class="ml-4 mt-3">
          {{ item.text }}
        </span>
        <v-spacer />
        <Button
          class="mr-2"
          color="indigo"
          :min-width="'30px'"
          :small="true"
          :elevation="0"
          :outlined="true"
          :button-style="{padding: '3px 3px 3px 3px'}"
          @click="$emit('alarmsetting')"
        >
          <v-icon>mdi-close</v-icon>
        </Button>
      </div>

      <div
        v-if="Object.keys(item).length > 0"
        style="background-color: #eff1f5"
      >
        <v-container>
          <Card class="">
            <v-card-text>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="20%">
                      <span class="text-h7"> <b>Alarm Açıklaması</b></span>
                    </td>
                    <td>
                      <span v-html="$t('alarm.' + item.alarmCode)" />
                    </td>
                  </tr>

                  <tr>
                    <td width="20%">
                      <span class="text-h7"><b> Alarm Durumu</b> </span>
                    </td>
                    <td>
                      <div class="d-flex">
                        <v-switch
                          v-model="item.status"
                          inset
                        />
                        <span
                          v-if="item.status"
                          class="mt-5"
                          v-html="$t('global.Active')"
                        />
                        <span
                          v-else
                          class="mt-5"
                          v-html="$t('global.Passive')"
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td width="20%">
                      <span class="text-h7">
                        <b> Alarm İlgilileri (mail)</b>
                      </span>
                    </td>
                    <td>
                      <v-combobox
                        v-model="model"
                        :hide-no-data="!search"
                        :items="mailList"
                        :search-input.sync="search"
                        hide-selected
                        label=""
                        multiple
                        small-chips
                        solo
                      >
                        <template v-slot:no-data>
                          <v-list-item dense>
                            <v-chip
                              color="lighten-3`"
                              label
                              small
                            >
                              {{ search }}
                            </v-chip>
                          </v-list-item>
                        </template>
                        <template v-slot:selection="{attrs, item, parent, selected}">
                          <v-chip

                            v-bind="attrs"
                            color="lighten-3"
                            :input-value="selected"
                            label
                            small
                          >
                            <span class="pr-2">
                              {{ item }}
                            </span>
                            <v-icon
                              small
                              @click="parent.selectItem(item)"
                            >
                              $delete
                            </v-icon>
                          </v-chip>
                        </template>
                        <template v-slot:item="{index, item}">
                          <v-text-field
                            v-if="editing === item"
                            v-model="editing"
                            autofocus
                            flat
                            background-color="transparent"
                            hide-details
                            solo
                            x-small
                            dense
                            @keyup.enter="edit(index, item)"
                          />
                          <v-chip
                            v-else
                            :color="`${index} lighten-3`"
                            dark
                            label
                            small
                          >
                            {{ item }}
                          </v-chip>
                          <v-spacer />
                          <v-list-item-action @click.stop>
                            <v-btn
                              icon
                              x-small
                              @click.stop.prevent="edit(index, item)"
                            >
                              <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </template>
                      </v-combobox>
                    </td>
                  </tr>
                  <tr>
                    <td width="20%">
                      <span class="text-h7">
                        <b> Alarm İlgilileri(Telegram)</b>
                      </span>
                    </td>
                    <td>
                      <v-combobox
                        v-model="modelTelegram"
                        :hide-no-data="!search"
                        :items="telegramList"
                        :search-input.sync="search"
                        hide-selected
                        label=""
                        multiple
                        small-chips
                        solo
                      >
                        <template v-slot:no-data>
                          <v-list-item dense>
                            <v-chip
                              color="lighten-3`"
                              label
                              small
                            >
                              {{ search }}
                            </v-chip>
                          </v-list-item>
                        </template>
                        <template v-slot:selection="{attrs, item, parent, selected}">
                          <v-chip

                            v-bind="attrs"
                            color="lighten-3"
                            :input-value="selected"
                            label
                            small
                          >
                            <span class="pr-2">
                              {{ item }}
                            </span>
                            <v-icon
                              small
                              @click="parent.selectItem(item)"
                            >
                              $delete
                            </v-icon>
                          </v-chip>
                        </template>
                        <template v-slot:item="{index, item}">
                          <v-text-field
                            v-if="editing === item"
                            v-model="editing"
                            autofocus
                            flat
                            background-color="transparent"
                            hide-details
                            solo
                            x-small
                            dense
                            @keyup.enter="edit1(index, item)"
                          />
                          <v-chip
                            v-else
                            :color="`${index} lighten-3`"
                            dark
                            label
                            small
                          >
                            {{ item }}
                          </v-chip>
                          <v-spacer />
                          <v-list-item-action @click.stop>
                            <v-btn
                              icon
                              x-small
                              @click.stop.prevent="edit1(index, item)"
                            >
                              <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </template>
                      </v-combobox>
                    </td>
                  </tr>

                  <tr v-if="item.params">
                    <td width="20%">
                      <span class="text-h7">
                        <b>
                          Karanlığı Yoksay <br>({{ item.params.ignore_night }}
                          dakika)
                        </b>
                      </span>
                    </td>
                    <td>
                      Gün doğumundan sonra ve gün batımından önce seçilen dakika
                      kadar uyarı kontrolünü atla
                      <v-slider
                        v-model="item.params.ignore_night"
                        class="align-center"
                        max="200"
                        min="10"
                        hide-details
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary darken-1"
                text
                @click="$emit('setAlarmUpdate', item)"
              >
                Ayarı Kaydet
              </v-btn>
            </v-card-actions>
          </Card>

          <div class="mt-3 mb-2">
            <ProductionandCommunication
              v-if="item.alarmCode === 'ProductionandCommunication'"
              :item="item"
            />
          </div>

          <div class="mt-3 mb-2">
            <DataLoggerAccess
              v-if="item.alarmCode === 'DataLoggerAccess'"
              :item="item"
            />
          </div>

          <div class="mt-3 mb-2">
            <UserDefinedAlarms
              v-if="item.alarmCode === 'UserDefinedAlarms'"
              :item="item"
            />
          </div>

          <div class="mt-3 mb-2">
            <InverterErrorWarning
              v-if="item.alarmCode === 'InverterErrorWarning'"
              :item="item"
            />
          </div>
        </v-container>
      </div>
    </Dialog>
  </div>
</template>

<script>

import helper from '@/api/helper';
import DataLoggerAccess from './alarmSetting/DataLoggerAccess.vue';
import UserDefinedAlarms from './alarmSetting/UserDefinedAlarms.vue';
import ProductionandCommunication from './alarmSetting/ProductionandCommunication.vue';
import InverterErrorWarning from './alarmSetting/InverterErrorWarning.vue';

export default {
  components: {
    DataLoggerAccess,
    ProductionandCommunication,
    UserDefinedAlarms,
    InverterErrorWarning,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['dialog', 'item', 'userlist'],
  data: () => ({
    mailModel: [],
    mailList: [],
    telegramList: [],
    editing: null,
    editingIndex: -1,
    nonce: 1,
    menu: false,
    model: [],
    modelTelegram: [],
    search: null,
  }),
  computed: {},
  watch: {
    model(val, prev) {
      if (!helper.is_Empty(val) && !helper.is_Empty(prev)) {
        if (val.length === prev.length) return;
      }
      if (!helper.is_Empty(val)) {
        this.model = val.map((v) => {
          if (typeof v === 'string') {
            this.userlist.mail.push(v);
            this.nonce += 1;
            return v;
          }

          if (typeof v === 'object') {
            this.userlist.mail.push(v.value);
            this.nonce += 1;
            return v;
          }
          return v;
        });
        this.item.userlist = this.model;
      }
    },

    modelTelegram(val, prev) {
      if (!helper.is_Empty(val) && !helper.is_Empty(prev)) {
        if (val.length === prev.length) return;
      }
      if (!helper.is_Empty(val)) {
        this.modelTelegram = val.map((v) => {
          if (typeof v === 'string') {
            if (this.userlist.mail.indexOf(v) === -1) {
              this.userlist.mail.push(v);
            }
            this.nonce += 1;
            return v;
          }

          if (typeof v === 'object') {
            if (this.userlist.telegram.indexOf(v.value) === -1) {
              this.userlist.telegram.push(v.value);
            }
            this.nonce += 1;
            return v;
          }
          return v;
        });
        this.item.telegram = this.modelTelegram;
      }
    },
    item() {
      if (!helper.is_Empty(this.item)) {
        if (!helper.is_Empty(this.item.userlist)) {
          this.item.userlist.forEach((item) => {
            if (typeof item === 'string') {
              if (this.mailList.indexOf(item) === -1) {
                this.mailList.push(item);
              }

              if (this.model.indexOf(item) === -1) {
                this.model.push(item);
              }
            }
            if (typeof item === 'object') {
              if (this.mailList.indexOf(item.value) === -1) {
                this.mailList.push(item.value);
              }
              if (this.model.indexOf(item.value) === -1) {
                this.model.push(item.value);
              }
            }
          });
        }

        if (!helper.is_Empty(this.item.telegram)) {
          this.item.telegram.forEach((item) => {
            if (typeof item === 'string') {
              if (this.telegramList.indexOf(item) === -1) {
                this.telegramList.push(item);
              }
              if (this.modelTelegram.indexOf(item) === -1) {
                this.modelTelegram.push(item);
              }
            }
            if (typeof item === 'object') {
              if (this.telegramList.indexOf(item.value) === -1) {
                this.telegramList.push(item.value);
              }
              if (this.modelTelegram.indexOf(item.value) === -1) {
                this.modelTelegram.push(item.value);
              }
            }
          });
        }
      }
    },
    userlist() {
      this.mailList = [];
      // eslint-disable-next-line array-callback-return
      this.userlist.mail.map((item) => {
        if (this.mailList.indexOf(item.value) === -1) {
          this.mailList.push(item.value);
        }
      });
    },
  },
  created() {},
  mounted() {
    this.mailList = [];
    this.model = [];
    if (!helper.is_Empty(this.userlist)) {
      if (!helper.is_Empty(this.userlist.mail)) {
        // eslint-disable-next-line array-callback-return
        this.userlist.mail.map((item) => {
          if (this.mailList.indexOf(item.value) === -1) {
            this.mailList.push(item.value);
          }
        });
      }
    }
    if (!helper.is_Empty(this.item)) {
      if (!helper.is_Empty(this.item.userlist)) {
        // eslint-disable-next-line array-callback-return
        this.item.userlist.map((item) => {
          if (this.mailList.indexOf(item.value) === -1) {
            this.mailList.push(item.value);
          }

          if (this.model.indexOf(item.value) === -1) {
            this.model.push(item.value);
          }
        });
      }
      if (!helper.is_Empty(this.item.telegram)) {
        // eslint-disable-next-line array-callback-return
        this.item.telegram.map((item) => {
          if (this.telegramList.indexOf(item.value) === -1) {
            this.telegramList.push(item.value);
          }
          if (this.modelTelegram.indexOf(item.value) === -1) {
            this.modelTelegram.push(item.value);
          }
        });
      }
    }
  },
  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    edit1(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
  },
};
</script>
